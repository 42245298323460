import { Col, Image, Row, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { ActivityWrapper } from "../../Common/ActivityCommon";

import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { FilterHeader } from "./filter-header";
import { IdeaModal } from "./idea-modal";
import { MockedFilters } from "../../../mock_data/filters";
import { MockedIdea } from "../../../mock_data/ideas";
import { activityRequests } from "../../../queries/activity.requests";
import { useMutation, useQuery } from "urql";
import { useHistory } from "react-router-dom";
import { ideaUtils } from "./idea-utils";
import { CustomText } from "../../Common/CustomText";
import _ from 'lodash';
import { IdeaContent } from "./idea-view";
import { utils } from "../../../lib/helper/utils";
import { IdeaEdit } from "./idea-edit";
import { ConfirmModal } from "../../Common/ConfirmModal";
import { MESSAGE } from "../../../lib/constants/message";

export const IdeaLayout  = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);
    const [ viewIdeaCarousal , setViewIdeaCarousal ] = useState(false);
    const [ editedIdea, setEditedIdea ] = useState({});
    const [ deletedIdea, setDeletedIdea ] = useState({});
    const [ modalType, setModalType ] = useState("");
    const [ ideaFilters, setIdeaFilters ] = useState({});
    const [ ideas, setIdeas ] = useState([]);
    const [ displayIdeas, setDisplayIdeas ] = useState([]);
    const [ goalOptions, setGoalOptions ] = useState([]);
    const [ stepOptions, setStepOptions ] = useState([]);
    const [ loadData, setLoadData ] = useState(false);
    const [ goalNameIdMap, setGoalNameIdMap ] = useState({});
    const [ stepNameIdMap, setStepNameIdMap ] = useState({});

    const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
    const [ executionResult, execution ] = useMutation(activityRequests.IDEA_DELETE);

    const [ queryResult, executeQuery ] = useQuery({
        query: activityRequests.ALL_IDEAS,
        requestPolicy: 'network-only'
    });

    const history = useHistory();

    const openIdeaModal = (type) => {
        setOpenModal(true);
        setModalType(type);
    }

    const updateIdeaView = (data, action) => {
        if (action === "add") {
            if (data !== null && data.hasOwnProperty("newIdea")) {
                setIdeas([...ideas, data.newIdea]);
                reOrderIdeas([...ideas, data.newIdea])
            }
            setOpenModal(false);
        } else if (action === "edit") {
            setViewIdeaCarousal(false);
            if (data !== null) {
                if (data.hasOwnProperty("updateIdea")) {
                    setOpenModal(false);
                    const updateIdea =  data.updateIdea;
                    let foundIdx =  ideas.findIndex(idea => idea.id === updateIdea.id);
                    ideas[foundIdx] = updateIdea;
                    setEditedIdea({});
                    reOrderIdeas(ideas);
                } else {
                    setOpenModal(true);
                    setEditedIdea(data);
                    const modalType = `${data.type.toLowerCase()}-idea`;
                    setModalType(modalType);
                }
            } else {
                setOpenModal(false);
                setEditedIdea({});
                setModalType("");
            }
        } else if (action === "delete") {
            setViewIdeaCarousal(false);
            setOpenConfirmDialog(true);
            setDeletedIdea(data);
            // This needs to be clear on delete op since its set when the idea is clicked
            setEditedIdea({});
        }
    }

    const updateFilteredIdeaView = (selectedGoals, selectedSteps) => {
        // console.log(`Goal filter ${JSON.stringify(selectedGoals)}`);
        // console.log(`Step filter ${JSON.stringify(selectedSteps)}`);

        if (selectedGoals.length === 0) {
            reOrderIdeas(ideas);
        } else {
            let filteredIdeas= ideas.filter(idea => selectedGoals.includes(idea.goalId));
            
            if (selectedSteps.length > 0) {
                filteredIdeas = filteredIdeas.filter(idea => selectedSteps.includes(idea.stepId));
            }

            reOrderIdeas(filteredIdeas);
        }
    }

    const reOrderIdeas = (ideas) => {
        if (ideas.length >= 3) {
            const ideaGrp =  _.mapValues(_.groupBy(ideas, "layoutColumn"),
            ideaList => ideaList.map(idea => idea));
            const orderIdeaList = ideaUtils.reOrderIdeas(ideaGrp);
            setDisplayIdeas(orderIdeaList);
        } else {
            setDisplayIdeas(ideas);
        }
    }

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            setLoading(false);

            if (error) {
                console.log(`Error: ${error.message}, flag ${process.env.REACT_APP_MOCKED_DATA_ENABLE}`);
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    const { goalOptions, stepOptions } = MockedFilters;
                    setGoalOptions(goalOptions);
                    setStepOptions(stepOptions);
                    setIdeas(MockedIdea.allIdeas);
                    reOrderIdeas(MockedIdea.allIdeas);
                    setGoalNameIdMap(utils.buildIdNameMap(goalOptions));
                    setStepNameIdMap(utils.buildIdNameMap(stepOptions));
                } else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
            } else {
                const { allGoals, allSteps, allIdeas } = data;
                setGoalOptions(allGoals);
                setStepOptions(allSteps);
                setIdeas(allIdeas);
                reOrderIdeas(allIdeas);
                setGoalNameIdMap(utils.buildIdNameMap(allGoals));
                setStepNameIdMap(utils.buildIdNameMap(allSteps));
            }

            setLoadData(true);    
        }
    }, [goalOptions, stepOptions, loadData, queryResult, history]);

    const editIdea = (idea) => {
        setViewIdeaCarousal(true);
        setEditedIdea(idea);
    }

    const onCloseConfirmModal  = () => {
        setOpenConfirmDialog(false);
        setDeletedIdea({});
    }

    const processIdeaDeletion = () => {
        setOpenConfirmDialog(false);
        setLoading(true);

        const variables = { ideaId:  deletedIdea.id};

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                deleteIdea(deletedIdea);
                setLoading(false)
            }
        });
    }

    const deleteIdea = (deletedIdea) => {
        const index =  ideas.indexOf(deletedIdea);

        if (index > -1) {
            ideas.splice(index, 1)
            reOrderIdeas(ideas);
        }
    }

    return (
        <ActivityWrapper>
             <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            { loadData && 
                <FilterHeader goalOptions={goalOptions} stepOptions={stepOptions}
                    updateFilters={setIdeaFilters} openModal={openIdeaModal}
                    updateFilteredView={updateFilteredIdeaView} page="idea"
                />
            }
            <Row gutter={[16, 16]} className="idea-view-list">
                { displayIdeas.map((idea, idx) => (
                    <Col className="gutter-row" span={idea.layoutColumn === 1 ? 6 : 12} 
                        key={idx} onClick={() => editIdea(idea)}>
                        <div className="box-idea-content" 
                            style={{ 
                                backgroundImage: idea.type === "IMAGE" ? `url(${idea.content.url})` : null,
                                position: "relative",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%"
                            }}>
                            <Row className="box-idea-content-body">
                                {IdeaContent(idea)}
                            </Row>
                            <Row className="box-idea-content-footer">
                                <Space direction="vertical">
                                    <Row style={{ alignItems:'center', padding: '2px 0', gap: '8px' }}>
                                        <Image src={`../../activities/${ideaUtils.getIdeaIcon(idea.type)}.png`} preview={false} />
                                        <CustomText weight={600} lg={16} md={14} ls="-0.025em" ratio={1.2}>
                                            {idea.name}
                                        </CustomText>
                                    </Row>
                                    <Row style={{ alignItems:'center' }} className="goal-step-text">
                                        { goalNameIdMap.hasOwnProperty(idea.goalId) &&
                                            <CustomText
                                                style={{ color: '#000000',
                                                        backgroundColor:'#FFD0D0',
                                                        padding: '0.2rem 0.5rem',
                                                        borderRadius: '16px',
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: `${idea.layoutColumn === 1 ? '12ch' : '25ch' }`
                                                    }}
                                                ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                                {goalNameIdMap[idea.goalId]}
                                            </CustomText>
                                        }
                                        &nbsp;
                                        { goalNameIdMap.hasOwnProperty(idea.goalId)  && stepNameIdMap.hasOwnProperty(idea.stepId) &&
                                            <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                                        }
                                        &nbsp;
                                        { stepNameIdMap.hasOwnProperty(idea.stepId) &&
                                            <CustomText
                                                style={{
                                                    color: "#000000",
                                                    backgroundColor: "#BBFBFF",
                                                    padding: "0.2rem 0.5rem",
                                                    borderRadius: "16px",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: `${idea.layoutColumn === 1 ? '15ch' : '25ch'}`
                                                }}
                                                ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                                {stepNameIdMap[idea.stepId]}
                                            </CustomText>
                                        }
                                    </Row>
                                </Space>
                            </Row>
                        </div>
                    </Col>
                ))}
            </Row>
            { openModal && 
                <IdeaModal modalType={modalType} isOpen={openModal} editedIdea={editedIdea}
                    goalOptions={goalOptions} stepOptions={stepOptions}
                    updateStateFromChild={updateIdeaView}/>
            }
            { viewIdeaCarousal &&
                <IdeaEdit updateStateFromChild={updateIdeaView} 
                    optionsMap={{goalNameIdMap, stepNameIdMap}}
                    editedIdea={editedIdea} allIdeas={displayIdeas}
                />
            }
            { openConfirmDialog && 
                <ConfirmModal isOpen={openConfirmDialog} title="Idea" message={MESSAGE.DELETION_CONFIRM("idea")} onClose={onCloseConfirmModal} action="Delete"
                    onConfirmAction={processIdeaDeletion}/>
            }
        </ActivityWrapper>
    )
}
import { Button, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Box } from "./CustomLayout";
import { CustomText } from "./CustomText";
import "../Common/less/activity.less";


export const ConfirmModal = (props) => {
    const { isOpen, title, message, action,
        onClose, onConfirmAction
    } = props;

    return (
      <Modal
        centered
        title={title}
        visible={isOpen}
        onCancel={onClose}
        footer={null}
        wrapClassName="confirm-modal"
      >
        <Box className="confirm-modal-layout">
          <CustomText weight={600} lg={18} md={16}>
            {message}
          </CustomText>
          <Space size="large" style={{ marginTop: '12%' }}>
            <Button
              key="submit"
              type="primary"
              onClick={onConfirmAction}
              data-testid="button-testid"
              style={{ backgroundColor: '#EF4046', borderRadius: '3px' }}
            >
              {action}
            </Button>
            <Button
              key="back"
              onClick={onClose}
              data-testid="button-testid"
              style={{
                border: '1px solid #EF4046',
                color: '#EF4046',
                borderRadius: '3px',
              }}
            >
              Cancel
            </Button>
          </Space>
        </Box>
      </Modal>
    );
}
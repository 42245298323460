import { Image, Space, Carousel, Row, Progress, Spin, Col, Tabs, Dropdown  } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActivityWrapper } from "../../Common/ActivityCommon";
import { Box } from "../../Common/CustomLayout";
import { CustomText } from "../../Common/CustomText";
import * as moment from "moment";

import "../../Common/less/step.less";
import { PrevStepIcon, NextStepIcon } from "./step-nav-icons";
import styled from "styled-components";
import { MockedStep } from "../../../mock_data/steps";
import { ModalCreate } from "../../Common/ModalCreate";
import { ConfirmModal } from "../../Common/ConfirmModal";
import { LoadingOutlined, CheckCircleFilled, RightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "urql";
import { MESSAGE } from "../../../lib/constants/message";
import { MockedTask } from "../../../mock_data/tasks";
import { MockedGoal } from "../../../mock_data/goals";
import { IdeaContent, IdeaMenuOptions } from "../ideas/idea-view";
import { IdeaModal } from "../ideas/idea-modal";
import { MockedIdea } from "../../../mock_data/ideas";
import { ideaUtils } from "../ideas/idea-utils";
import _ from 'lodash';
import { TodoModal } from "../todos/todo-modal";
import { ShareModal } from "../../Common/ShareModal";
import { stepRequests } from "../../../queries/step.requests";

const StepDetailWrapper = styled.div`
    background-image: url(${props => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    color: #FFFFFF;
    overflow: hidden;

    &:before {
        background: rgba(0, 0, 0, 0.12);
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
`;

const stepCarouselWrapper = {
    marginTop: "30px",
    borderRadius: "16px",
    marginBottom: "20px"
}

const styleActiveStep = {
    backgroundColor: '#ef4046',
    borderRadius: '25px', 
    color: '#FFFFFF'
}

const carouselSettings = {
    prevArrow: <PrevStepIcon />,
    nextArrow: <NextStepIcon />,
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500
};

var carouselRef =  React.createRef();
const { TabPane } = Tabs;

export const StepDetailLayout = (props) => {
    const { goalId, stepId } = props.match.params;
    const [ loadData, setLoadData ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ waitForData, setWaitForData ] = useState(true);

    const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
    const [ stepModal, setStepModal ] = useState(false);
    const [ shareModal, setShareModal ] = useState(false);
    const [ ideaModal, setIdeaModal ] = useState(false);
    const [ todoModal, setTodoModal ] = useState(false);

    const [ allSteps, setAllSteps ] = useState([]);
    const [ allIdeas, setAllIdeas ] = useState([]);
    const [ allTasks, setAllTasks ] = useState([]);

    // Variables to hold displayed ideas and displayed tasks
    const [ displayIdeas, setDisplayIdeas ] = useState([]);
    const [ displayTasks, setDisplayTasks ] = useState([]);

    const [ currentStepId, setCurrentStepId ] = useState(stepId);
    const [ currentGoal, setCurrentGoal ] = useState({});
    let [ currentStep, setCurrentStep ] = useState({});
    const [ stepLock, setStepLock ] = useState(false)

    const [ iCurrentSlide, setICurrentSlide ] = useState(0);
    const [ firstStep, setFirstStep ] = useState(1);
    const [ secondStep, setSecondStep ] = useState(2);
    const [ thirdStep, setThirdStep ] = useState(3);

    const [ currentTab, setCurrentTab ] = useState("idea");
    const [ ideaModalType, setIdeaModalType ] = useState("");

    const [ executionResult, execution ] = useMutation(stepRequests.STEP_DELETE);
    const [ executionStepUpdateResult, executionStepUpdate ] = useMutation(stepRequests.STEP_UPDATE);

    const [ queryResult, executeQuery ] = useQuery({
        query: stepRequests.STEP_DETAILS,
        variables: {goalId: goalId},
        requestPolicy: 'network-only'
    });

    const history = useHistory();

    const reOrderIdeas = (ideas) => {
        if (ideas.length >= 3) {
            const ideaGrp =  _.mapValues(_.groupBy(ideas, "layoutColumn"),
            ideaList => ideaList.map(idea => idea));
            const orderIdeaList = ideaUtils.reOrderIdeas(ideaGrp);
            setDisplayIdeas(orderIdeaList)
        } else {
            setDisplayIdeas(ideas);
        }
    }

    const stepMappedById = (steps) => {
        return steps.reduce((map, s) => {
            map[s.id] = s;
            return map;
        }, {});
    }

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setLoading(true);
                return;
            }

            if (error) {
                console.log(`Error: ${error.message}`)
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    const currentGoal = MockedGoal.allGoals.find(g => g.id === goalId);
                    setCurrentGoal(currentGoal);
                    const filteredSteps = MockedStep.allSteps.filter(step => step.goalId === goalId);
                    setAllSteps(filteredSteps);
                    setAllIdeas(MockedIdea.allIdeas);
                    setAllTasks(MockedTask.tasksByGoals);
                } else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
            } else {
                const { currentGoal, allSteps = [], allIdeas = [], allTasks = [] } = data;
                setCurrentGoal(currentGoal);
                setAllSteps(allSteps);
                setAllIdeas(allIdeas);
                setAllTasks(allTasks);
            }
            setLoadData(true);
            setWaitForData(false)
        }

        if (loadData && allSteps.length >= 0 && !waitForData) {

            const stepIdMap = stepMappedById(allSteps);

            const step =  stepIdMap[currentStepId];

            const currentSlide = allSteps.indexOf(step);

            step.order =  currentSlide + 1;

            setCurrentStep(step);
            setStepLock(step.permission === 'VIEW')

            carouselRef.goTo(currentSlide, true);
        
            let currentSlideVal = currentSlide;
            if (currentSlide > 0) {
                currentSlideVal = currentSlide - 1;
            }
            if(currentSlide === allSteps.length - 1) {
                currentSlideVal = currentSlide - 2;
            }

            setICurrentSlide(currentSlide);
            setFirstStep(currentSlideVal + 1);
            setSecondStep(currentSlideVal + 2);
            setThirdStep(currentSlideVal  + 3);

            if (allIdeas.length > 0) {
                const filteredIdeas =  allIdeas.filter(idea => idea.goalId === goalId && idea.stepId === stepId);
                reOrderIdeas(filteredIdeas);
            }

            if (allTasks.length > 0) {
                const filteredTasks =  allTasks.filter(task => task.goalId === goalId && task.stepId === stepId);
                setDisplayTasks(filteredTasks);
            }

            setLoading(false);
        }
    }, [history, queryResult, currentStepId, goalId, stepId, allSteps, allIdeas, allTasks, loadData, waitForData])

    // Support idea dropdown in step details
    const updateIdeaView = (data) => {
        setIdeaModal(false);
        
        if (data !== null && data.hasOwnProperty("newIdea")) {
            reOrderIdeas([...displayIdeas, data.newIdea]);
        }
    }

    const updateTaskView = (data, oldData = null) => {
        setTodoModal(false);

        if (data !== null && data.hasOwnProperty("newTask")) {
            setDisplayTasks([...displayTasks, data.newTask]);
        }
    }

    const openIdeaModal = (type) => {
        setIdeaModal(true);
        setIdeaModalType(type);
    }

    const openTodoModal = () => {
        setTodoModal(true);
    }

    const updateStepView = (data) => {
        let updateStepId;

        if (data !== null) {
            if (data.hasOwnProperty("photoUrl")) {
                updateStepId = data.id;
                currentStep.url = data.photoUrl;
            } else {
                updateStepId = data.updateStep.id;
                Object.assign(currentStep, data.updateStep);
            }

            const updateStepIdx = allSteps.findIndex(step => step.id === updateStepId);

            allSteps[updateStepIdx] =  currentStep;
            setAllSteps(allSteps);
        }
        
        setStepModal(false);
    }

    const editStepModal = () => setStepModal(true)

    const openShareModal = () => setShareModal(true)

    const updateSharingStatus = (data) => {
        setShareModal(false)
    }

    const handleSlide = (currentSlide) => {
        setCurrentStepId(allSteps[currentSlide].id);
        const nextUrl = `/activity/goals/${props.match.params.goalId}/steps/${allSteps[currentSlide].id}`;
        window.history.replaceState(null, null, nextUrl);

        // Filter ideas and tasks based on current step (filter key: stepId)
        const filteredIdeas = allIdeas.filter(idea => idea.goalId === goalId && idea.stepId === allSteps[currentSlide].id);
        reOrderIdeas(filteredIdeas);
        const filteredTasks = allTasks.filter(task => task.goalId === goalId && task.stepId === allSteps[currentSlide].id);
        setDisplayTasks(filteredTasks);
    }

    const deleteStep = () => {
        setLoading(true);
        const variables = { stepId:  currentStep.id, isTrashed: true };
        
        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                setLoading(false);
                history.push( {
                    pathname: `/activity/goals/${currentStep.goalId}`
                });
            }
        });
    }

    const onOpenConfirmModal = () => setOpenConfirmDialog(true);
    const onCloseConfirmModal  = () => setOpenConfirmDialog(false);

    const btnAddNewInTabs = currentTab === "idea" ?
                            (
                                <Dropdown overlay={IdeaMenuOptions(openIdeaModal, "../../")}
                                    placement="bottomRight" trigger={['click']}>
                                    <PlusCircleFilled style={{
                                            fontSize: '35px',
                                            color: 'rgb(239, 64, 70)',
                                            padding: '0.5rem 0',
                                            margin: '0.2rem 0.5rem 0.5rem 0'
                                    }} />
                                </Dropdown>
                            ) : (
                                <PlusCircleFilled
                                    onClick={openTodoModal}
                                    style={{
                                        fontSize: '35px', 
                                        color: 'rgb(239, 64, 70)',
                                        padding: '0.5rem 0',
                                        margin: '0.2rem 0.5rem 0.5rem 0'
                                    }} 
                                />
                            )

    const onChangeTab = (key) => {
        setCurrentTab(key);
    }

    const markComplete = (step) => {
        setLoading(true);
        
        const variables = {
            id: step.id,
            progress:  step.progress < 100 ? 100 : 0
        };

        executionStepUpdate(variables).then(result => {
            setLoading(false);
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`)
            } else {
                console.log(`result ${JSON.stringify(result.data)}`)
                currentStep.progress = result.data.updateStep.progress
                setCurrentStep(currentStep)
            }
        });
    }

    return (
        <ActivityWrapper>
             <Spin className="loading" spinning={loading}
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <Row>
                { currentStep && 
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Box direction="row" id="step-detail-header">
                            <Space direction="horizontal" size="small">
                                <Image className="resize-icon hand-cursor" src="/activities/back-button.png" preview={false} onClick={() => history.goBack()}/>
                                <Space direction="vertical" size={4}>
                                    <CustomText weight={600} lg={22} md={18} ratio={1.2} ls="-0.0225em" style={{color: "#000000"}}>
                                        <CustomText weight={600} lg={24} md={20} ratio={1.2} ls="-0.0225em">
                                            Step {currentStep.order}
                                            <CheckCircleFilled style={{ 
                                                fontSize: "28px",
                                                paddingLeft: "10px",
                                                color: `${currentStep.progress === 100 ? "#4ECF72" : "#B3B3B3"}`}} 
                                                onClick={() => markComplete(currentStep)}/>
                                        </CustomText>
                                    </CustomText>
                                    <CustomText weight={500} lg={12} md={10} ratio={1.2} ls="-0.0225em" style={{color: "#868585"}}>
                                        {currentStep.description}
                                    </CustomText>
                                </Space>
                            </Space>
                        </Box>
                    </Col>
                }
                { allSteps.length >=3 && 
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Row justify="center" align="middle" style={{height: '100%'}}>
                            <Row style={{width: '85%', 
                                height: '45%',
                                backgroundColor: '#f2eded',
                                borderRadius: '25px'}}>
                                
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} 
                                    style={iCurrentSlide === 0 ? styleActiveStep : ''}>
                                    <Row justify="center" align="middle" style={{height: '100%'}}>
                                        <span>{firstStep.toString().length < 2 ? '0'+ firstStep :  firstStep}</span> 
                                    </Row>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}
                                    style={ 0 < iCurrentSlide && iCurrentSlide < allSteps.length - 1 ? styleActiveStep : ''}>
                                    <Row justify="center" align="middle" style={{height: '100%'}}>
                                        <span>{secondStep.toString().length < 2 ? '0'+ secondStep :  secondStep}</span> 
                                    </Row>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}
                                style={iCurrentSlide === allSteps.length - 1 ? styleActiveStep : ''}>
                                    <Row justify="center" align="middle" style={{height: '100%'}}>
                                        <span>{thirdStep.toString().length < 2 ? '0'+ thirdStep :  thirdStep}</span> 
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                }
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
            { !waitForData && 
                <Carousel  
                    ref={(el) => (carouselRef = el)}
                    style={stepCarouselWrapper}
                    afterChange={handleSlide}
                    {...carouselSettings}
                >   
                {
                    allSteps.map((step,iStep) => (
                        <Space key={iStep} direction="horizontal">
                            <div className="box-step">
                                <StepDetailWrapper url={step.url}>
                                    <Row justify="end" style={{ marginTop: "15px", marginRight: "2%" }}>
                                        <Space direction="horizontal" size="middle">
                                            { step.permission == null && 
                                                <Image className="hand-cursor" src="/activities/share.png" preview={false} 
                                                    onClick={openShareModal} />
                                            }
                                            { step.permission !== 'VIEW' &&
                                                <Space>
                                                    <Image className="hand-cursor" src="/activities/pencil.png" preview={false}
                                                        onClick={editStepModal} />
                                                    <Image className="hand-cursor" src="/activities/trash-bin.png" preview={false} 
                                                        onClick={onOpenConfirmModal} />
                                                </Space>
                                            }
                                        </Space>
                                    </Row>
                                    <Row style={{ position: "absolute", bottom: "8%", marginLeft: "2%"}}>
                                        <Space direction="vertical" size={4}>
                                            <CustomText weight={600} lg={29} md={24} ratio={1.2}>{step.name}</CustomText>
                                            <CustomText weight={500} lg={14} md={12}>
                                                Due on {moment.utc(step.dueDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
                                            </CustomText>
                                        </Space>
                                    </Row>
                                </StepDetailWrapper>
                            </div>
                            <Progress percent={step.progress} style={{ paddingTop: "15px", fontWeight: "500" }}
                                    strokeColor={step.progress < 30 ? '#ff4848' : step.progress >= 30 && step.progress < 50 ? '#dbaf14' : '#4ecf72'}/>
                        </Space>
                    ))
                }
                </Carousel>
            }
            { stepModal && 
                <ModalCreate isOpen={stepModal} type="step"
                    data={currentStep} userId={currentGoal.userId}
                    updateStateFromChild={updateStepView}/>
            }
            { shareModal &&
                <ShareModal isOpen={shareModal} data={currentStep} 
                    type="step" recordId={currentStep.id}
                    updateStateFromChild={updateSharingStatus}/>
            }
            { openConfirmDialog && 
                <ConfirmModal isOpen={openConfirmDialog} title="Step" message={MESSAGE.DELETION_CONFIRM("step")} onClose={onCloseConfirmModal} action="Delete"
                onConfirmAction={deleteStep}/>
        }
            { ideaModal && 
                <IdeaModal modalType={ideaModalType}  goalOptions={[currentGoal]} stepOptions={[currentStep]} allowOptions={false}
                    isOpen={ideaModal}  updateStateFromChild={updateIdeaView} />
            }
            { todoModal &&
                <TodoModal isOpen={todoModal} goalOptions={[currentGoal]} stepOptions={[currentStep]} allowOptions={false}
                    updateStateFromChild={updateTaskView} />
            }

            <Tabs defaultActiveKey="idea" onChange={onChangeTab} tabBarExtraContent={stepLock ? null : btnAddNewInTabs}
                style={{ backgroundColor: "#f9f9f9", marginTop: "0.2rem", marginBottom: "0.2rem", padding: '0.2rem 0'}}>
                <TabPane key="idea" tab={
                        <CustomText lg={18} md={16} weight={600} ls="-0.025em" ratio={1.2}>
                            Ideas
                        </CustomText>
                    } >
                   <Row gutter={[16, 16]}>
                        {
                           displayIdeas.map((idea, idx) => (
                                <Col className="gutter-row" span={idea.layoutColumn === 1 ? 6 : 12} key={idx}>
                                    <div className="box-idea-content" 
                                        style={{ 
                                            backgroundImage: idea.type === "IMAGE" ? `url(${idea.content.url})` : null,
                                            position: "relative",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%"
                                        }}>
                                        <Row className="box-idea-content-body">
                                            {IdeaContent(idea,  "../../")}
                                        </Row>
                                        <Row className="box-idea-content-footer">
                                            <Space direction="vertical">
                                                <Row style={{ alignItems:'center', padding: '2px 0', gap: '8px' }}>
                                                    <Image src={`../../../../activities/${ideaUtils.getIdeaIcon(idea.type)}.png`} preview={false} />
                                                    <CustomText weight={600} lg={16} md={14} ls="-0.025em" ratio={1.2}>
                                                        {idea.name}
                                                    </CustomText>
                                                </Row>
                                                <Row style={{ alignItems:'center' }} className="goal-step-text">
                                                    <CustomText
                                                        style={{ color: '#000000',
                                                                backgroundColor:'#FFD0D0',
                                                                padding: '0.2rem 0.5rem',
                                                                borderRadius: '16px',
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: `${idea.layoutColumn === 1 ? '12ch' : '25ch' }`
                                                            }}
                                                        ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                                        {currentGoal.name}
                                                    </CustomText>
                                                    &nbsp;
                                                    <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                                                    &nbsp;
                                                    <CustomText
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "#BBFBFF",
                                                            padding: "0.2rem 0.5rem",
                                                            borderRadius: "16px",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: `${idea.layoutColumn === 1 ? '15ch' : '25ch'}`
                                                        }}
                                                        ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2}>
                                                        {currentStep.name}
                                                    </CustomText>
                                                </Row>
                                            </Space>
                                        </Row>
                                    </div>
                                </Col>
                           ))
                        }
                   </Row>
                </TabPane>
                <TabPane key="task" tab={
                        <CustomText lg={18} md={16} weight={600} ls="-0.025em" ratio={1.2}>
                            Tasks
                        </CustomText>
                    }>
                    <Row>
                        {                        
                            displayTasks.map((task,iTask) => (
								<Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} 
                                    key={iTask}
                                    style={{ 
                                        paddingTop: '1%', 
                                        paddingRight: '1%', 
                                        paddingLeft: iTask % 3 === 0 ? '1%' : '0'
                                    }}>
                                    <div className="box-task-content" 
                                        style={{ borderRadius: '16px', height: 'calc(100% - 10px)', position: 'relative'}}>
                                        <Row style={{ alignItems: 'center' }}>
                                            <CheckCircleFilled style={{ color: `${task.color}`, fontSize: '120%'}} />
                                            &nbsp;
                                            <span className={`${task.isComplete ? "task-complete" : "task-incomplete"}`}>
                                                <CustomText ls="-0.025em" lg={16} md={14} ratio={1.2}
                                                    weight={`${task.isComplete ? 500 : 600}`}>
                                                    {task.name}
                                                </CustomText>
                                            </span>
                                        </Row>
                                        <Row style={{ paddingTop: '0.5rem', marginBottom: '37px' }}>
                                            <Col>
                                                <span style={{ color: '#a5a5a5'}}>
                                                    {task.description}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{ position: 'absolute', bottom: '15px', width: 'calc(100% - 30px)' }}>
                                            <Col xs={9} sm={9} md={9} lg={8} xl={8} xxl={8}>
                                                <CustomText style={{ color: '#000000'}} ls="-0.025em" lg={14} md={10} weight={600} ratio={1.2}>
                                                    {moment.utc(task.dueDate, "YYYY-MM-DD HH:mm").format("MMM DD, YYYY")}
                                                </CustomText>
                                            </Col>
                                            <Col xs={15} sm={15} md={15} lg={16} xl={16} xxl={16} 
                                                style={{ textAlign: 'end', alignItems:'center' }}>
                                                <Row style={{ alignItems:'center', justifyContent: 'end' }} className='goal-step-text'>
                                                    <CustomText className="task-goal-tag" 
                                                        ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                                        {currentGoal.name}
                                                    </CustomText>
                                                    &nbsp;
                                                    <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                                                    &nbsp;
                                                    <CustomText className="task-step-tag"
                                                        ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                                        {currentStep.name}
                                                    </CustomText>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>                    
                </TabPane>
            </Tabs>
        </ActivityWrapper>
    )
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { ActivityWrapper } from "../../Common/ActivityCommon";
import { ModalCreate } from "../../Common/ModalCreate";
import { GoalDetailHeader } from "./goal-details-header";
import { LoadingOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Col, Row, Progress, Spin, Space, Image } from "antd";
import { CustomText } from "../../Common/CustomText";
import * as moment from "moment";
import Carousel from "react-multi-carousel";
import "../../Common/less/activity.less";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";

import { MockedStep } from "../../../mock_data/steps";
import { MockedTask } from "../../../mock_data/tasks";
import { MockedIdea } from "../../../mock_data/ideas";
import { ideaUtils } from "../ideas/idea-utils";
import { IdeaContent } from "../ideas/idea-view";
import { MockedGoal } from "../../../mock_data/goals";
import { utils } from "../../../lib/helper/utils";
import { ShareModal } from "../../Common/ShareModal";
import { goalRequests } from "../../../queries/goal.requests";
import { InfoModal } from "../../Common/InfoModal";

const StepWrapper = styled.div`
    background-image: url(${props => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 0;
    color: #FFFFFF;
    overflow: hidden;

    &:before {
        background: rgba(0, 0, 0, 0.12);
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
`;

var carousel = React.createRef();

export const GoalDetailLayout = (props) => {
    const [ goal, setGoal ] = useState(props.location.goal);
    const [ allSteps, setAllSteps ] = useState([]);
    const [ stepModal, setStepModal ] = useState(false);
    const [ goalModal, setGoalModal ] = useState(false);
    const [ shareModal, setShareModal ] = useState(false);
    const [ spin, setSpin ] = useState(false);
    const [ stepNameIdMap, setStepNameIdMap ] = useState({});
    const [ ideas, setIdeas ] = useState([]);
    const [ tasks, setTasks ] = useState([]);
    const [ loadData, setLoadData ] = useState(false);
    const [ error, setError ] = useState('');
    const history = useHistory();

    const [ executionResult, execution ] = useMutation(goalRequests.GOAL_DELETE);

    const goalId = goal ? goal.id : props.match.params.id;

    const [ queryResult, executeQuery ] = useQuery({
        query: goalRequests.GOAL_DETAILS,
        variables: {goalId: goalId},
        requestPolicy: 'network-only'
    });

    useEffect(() => {
        if (!loadData) {
            const { data, fetching, error } = queryResult;

            if (fetching) {
                setSpin(true);
                return;
            }

            if (error) {
                console.log(`Error: ${error.message}`);
                const errorMsg =  error.graphQLErrors.at(0).message;
                if (process.env.REACT_APP_MOCKED_DATA_ENABLE === "true") {
                    if (goal == null) {
                        const currentGoal = MockedGoal.allGoals.find(g => g.id === goalId);
                        setGoal(currentGoal);
                    }
                    const stepsByGoalId =  MockedStep.allSteps.filter(s => s.goalId === goalId);
                    const ideasByGoalId =  MockedIdea.allIdeas.filter(i => i.goalId === goalId);
                    const tasksByGoalId =  MockedTask.tasksByGoals.filter(t => t.goalId === goalId);
                    setAllSteps(stepsByGoalId);
                    setIdeas(ideasByGoalId);
                    setTasks(tasksByGoalId);
                    setStepNameIdMap(utils.buildIdNameMap(MockedStep.allSteps));
                }else if(errorMsg === "Goal doesn't exist"){
                    setError(errorMsg);
                }else {
                    history.push({
                        pathname: "/",
                        event: "expired-user-login-session"
                    });
                }
            } else {
                const { currentGoal, allIdeas = [], allSteps = [], allTasks = []} = data;
                setGoal(currentGoal);
                setAllSteps(allSteps);
                setIdeas(allIdeas);
                setTasks(allTasks);
                setStepNameIdMap(utils.buildIdNameMap(allSteps));
            }

            setSpin(false);
            setLoadData(true);
        }
    }, [loadData, goalId, goal, allSteps, history, queryResult])

    const navLeft = () => {
        carousel.previous();
    }

    const navRight = () => {
        carousel.next();
    }

    const updateStateFromChild = (data) => {
        setStepModal(false);

        if (data !== null && data.hasOwnProperty("newStep")) {
            setAllSteps([...allSteps, data.newStep]);
        }
    }

    const updateGoalView = (data) => {
        setGoalModal(false)

        if (data != null && data.updateGoal != null) {
            setGoal(data.updateGoal)
        }
    }

    const updateSharingStatus = (data) => {
        setShareModal(false)
    }

    const openStepModal = () => setStepModal(true)
    const editGoalModal = () => setGoalModal(true)
    const openShareModal = () => setShareModal(true)

    const deleteGoal = (goalId) => {
        setSpin(true);

        console.log(`Deleted goal id ${goalId}`);
        const variables = { goalId:  goalId, isTrashed: true };

        execution(variables).then(result => {
            if (result.error) {
                console.log(`Error: ${result.error.graphQLErrors[0].message}`);
            } else {
                setSpin(false);
                handleClose();
            }
        });
    }

    const loadStepDetails = (step) => {
        const redirectUrl =  `/activity/goals/${goal.id}/steps/${step.id}`;

        history.push({
            pathname: redirectUrl
        })
    }

    const viewAllTasks = () => {
        const redirectUrl =  "/activity/todos";

        history.push({
            pathname: redirectUrl,
        })
    }

    const viewAllIdeas = () => {
        const redirectUrl =  "/activity/ideas";

        history.push({
            pathname: redirectUrl,
        })
    }

    const handleClose = () =>{
        history.push( {
            pathname: "/activity/goals"
        });
    }

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5.5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3.5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2.5
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1.5
        }
    };
    return (
        <ActivityWrapper>
            { goal &&
                <GoalDetailHeader goal={goal}
                        navLeft={navLeft} navRight={navRight}
                        openStepModal={openStepModal} 
                        editGoalModal={editGoalModal} 
                        deleteGoal={deleteGoal}
                        shareModal={openShareModal}
                />
            }

            <Spin className="loading" spinning={spin}
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />

            { stepModal &&
                <ModalCreate isOpen={stepModal} type="step" goalId={goalId}
                    userId={goal.userId}
                    updateStateFromChild={updateStateFromChild}/>
            }
            { goalModal &&
                <ModalCreate isOpen={goalModal} type="goal" data={goal}
                    userId={goal.userId}
                    updateStateFromChild={updateGoalView}/>
            }
            { shareModal &&
                <ShareModal isOpen={shareModal} data={goal}
                    type="goal" recordId={goal.id}
                    updateStateFromChild={updateSharingStatus}/>
            }
            { allSteps.length > 0 &&
                <div className="wrapper-react-multi-carousel-list">
                    <Carousel responsive={responsive}
                        arrows={false}
                        swipeable={false}
                        draggable={false}
                        ref={(el) => (carousel = el)}>
                        {
                            allSteps.map((step,iStep) => (
                                <div key={iStep}>
                                    <div className="box-goal"
                                        style={{ paddingRight: '5%', marginBottom: '2%'}}>
                                        <StepWrapper url={step.url} onClick={() => loadStepDetails(step)}>
                                            <Row justify="center" style={{ position:'absolute', width: '100%', top : '3%' }}>
                                                <Col span={21}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <CustomText ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                                Due on {moment.utc(step.dueDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
                                                            </CustomText>
                                                        </Col>
                                                        <Col span={12} style={{textAlign: 'end'}}>
                                                            <CustomText ls="-0.025em" weight={500} lg={25} md={25} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                                {iStep < 9 ? '0' + (iStep+1) : (iStep+1)}
                                                            </CustomText>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row justify="center" style={{ position:'absolute', width: '100%', bottom : '3%' }}>
                                                <Col span={21}>
                                                    <CustomText ls="-0.025em" weight={500} lg={24} md={20} ratio={1.2} style={{color: "#FFFFFF"}}>
                                                        {step.name}
                                                    </CustomText>
                                                </Col>
                                                <Col span={21}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <CustomText ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                            {step.tasks > 0 ? `${step.tasks} Tasks` : ''}
                                                            {step.tasks > 0 && step.ideas > 0 ? <> &middot; </> : ''}
                                                            {step.ideas > 0 ? `${step.ideas} Ideas` : '' }
                                                            </CustomText>
                                                        </Col>
                                                        <Col span={12} style={{textAlign: 'end'}}>
                                                            <CustomText ls="-0.025em" weight={500} lg={14} md={12} ratio={1.2} style={{ color: "#FFFFFF" }}>
                                                                {step.progress} %
                                                            </CustomText>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={21}>
                                                    <Progress percent={step.progress} showInfo={false}
                                                        strokeColor={step.progress < 30 ? '#ff4848' : step.progress >= 30 && step.progress < 70 ? '#dbaf14' : '#4ecf72'}/>
                                                </Col>
                                            </Row>
                                        </StepWrapper>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            }
            { (ideas.length > 0 || tasks.length) > 0 &&
                <Row style={{marginTop: '1%', marginBottom: '1%'}}>
                    <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14} style={{ paddingRight: '0.5%'}}>
                        <div className="box-content">
                            <Row style={{ marginBottom: '20px'}}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <CustomText ls="-0.025em" weight={600} lg={24} md={20} ratio={1.2}>
                                        Ideas
                                    </CustomText>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Row justify="end">
                                        <Col>
                                            <CustomText onClick={viewAllIdeas} style={{ color: '#ef3b42', cursor: 'pointer'}} ls="-0.025em" weight={600} lg={14} md={12} ratio={1.2}>
                                                View all ideas &#62;
                                            </CustomText>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} className="wrapper-box-tasks">
                                { ideas.map((idea, idx) => (
                                    <Col className="gutter-row" span={12} key={idx}>
                                        <Col className="box-idea-content"
                                        style={{
                                            backgroundImage: idea.type === "IMAGE" ? `url(${idea.content.url})` : null,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%"
                                        }}>
                                            <Row className="box-idea-content-body">
                                                {IdeaContent(idea)}
                                            </Row>
                                            <Row className="box-idea-content-footer">
                                                <Space direction="vertical" style={{ width: "100%"}}>
                                                    <Row style={{ alignItems:'center', padding: '2px 0' }}>
                                                        <Image src={`../../activities/${ideaUtils.getIdeaIcon(idea.type)}.png`} preview={false} /> 
                                                        <CustomText weight={600} lg={16} md={14} ls="-0.025em" ratio={1.2}>
                                                            {idea.name}
                                                        </CustomText>
                                                    </Row>
                                                    <Row style={{ alignItems:'center', width: "100%", paddingTop: '3%'}}>
                                                        {/* <CustomText className="idea-goal-tag"
                                                            ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                                            {goal.name}
                                                        </CustomText>
                                                        &nbsp;
                                                        <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                                                        &nbsp; */}
                                                        <CustomText className="idea-step-tag"
                                                            ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                                            {stepNameIdMap[idea.stepId]}
                                                        </CustomText>
                                                    </Row>
                                                </Space>
                                            </Row>
                                        </Col>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10} style={{ paddingLeft: '0.5%'}}>
                        <div className="box-content">
                            <Row style={{ marginBottom: '20px'}}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <CustomText ls="-0.025em" weight={600} lg={24} md={20} ratio={1.2}>
                                        Tasks
                                    </CustomText>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Row justify="end">
                                        <Col>
                                            <CustomText onClick={viewAllTasks} style={{ color: '#ef3b42', cursor: 'pointer'}} ls="-0.025em" weight={600} lg={14} md={12} ratio={1.2}>
                                                View all tasks &#62;
                                            </CustomText>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="wrapper-box-tasks">
                                {
                                    tasks.map((task,iTask) => (
                                        <div key={iTask} className="box-task-content">
                                            <Row>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                    <CheckCircleFilled style={{ color: `${task.color}`}} />
                                                    &nbsp;
                                                    <span className={`${task.isComplete ? "task-complete" : "task-incomplete"}`}>
                                                        <CustomText ls="-0.025em" lg={16} md={14} ratio={1.2}
                                                            weight={`${task.isComplete ? 500 : 600}`}>
                                                            {task.name}
                                                        </CustomText>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <CustomText ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2} style={{ color: '#878686'}}>
                                                        {stepNameIdMap[task.stepId]}
                                                    </CustomText>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <Row justify="end">
                                                        <Col>
                                                            <CustomText style={{ color: '#000000'}} ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                                                {moment.utc(task.dueDate, "YYYY-MM-DD").format("ddd, DD MMM YYYY")}
                                                            </CustomText>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            <InfoModal isOpen={error !== ""} title={"error"} message={error} onClose={handleClose}/>
        </ActivityWrapper>
    )
}
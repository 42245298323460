import React, { useEffect, useState } from 'react';
import { Form, Input, Space, Tooltip, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Box } from "../Common/CustomLayout";
import "../../components/Common/less/signup.less";
import { CustomText } from "../Common/CustomText";
import { CustomButton } from "../Common/CustomButton";
import { utils } from "../../lib/helper/utils";
import { userRequests } from "../../queries/user.requests";
import { useMutation } from "urql";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { deleteToken } from '../../lib/helper/token';

export const SignupForm = (props) => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [spin, setSpin] = useState(false);
    const { onLogin, onCloseSignUp } = props;

    let history = useHistory();

    const [executionResult, execution] = useMutation(userRequests.SIGNUP);

    const signup = async (values) => {
        let err = errorCheck(values);

        if (err.length === 0) {
            setSpin(true);
            deleteToken();

            const variables = { 
                name: values.fullname, 
                email: values.email, password: 
                values.password, 
                invitationCode: props.invitationCode 
            };

            execution(variables).then(result => {
                setSpin(false);
                if (result.error) {
                    console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                    setError(result.error.graphQLErrors[0].message);
                } else {
                    const newUser = result.data.newUser;
                    console.log(`result ${JSON.stringify(newUser)}`);
                    onCloseSignUp();

                    let event = newUser.active ? "user-activation-success" : "new-account-activation-link";
                    history.push({
                        pathname: "/",
                        event: event
                    })
                }
            })
        } else {
            setError(err);
        }
    }

    const errorCheck = (values) => {
        let errorMsg = '';

        if (utils.isEmpty(values.fullname)) {
            errorMsg = "Full Name is required.";
        } else if (utils.isEmpty(values.email)) {
            errorMsg = "Email is required.";
        } else if (!utils.validateEmail(values.email)) {
            errorMsg = "Please enter valid email.";
        } else if (utils.isEmpty(values.password)) {
            errorMsg = "Password is required.";
        } else if (!utils.hasValidPassword(values.password)) {
            errorMsg = "Password does not meet requirements.";
        } else if (utils.isEmpty(values.confirmPassword)) {
            errorMsg = "Please confirm your password."
        } else if (values.password !== values.confirmPassword) {
            errorMsg = "Passwords do not match."
        }

        return errorMsg;
    }

    return (
        <Box className="signup-form">
            <Box className="error-box" style={{ background: error.length > 0 ? "#EF4046" : "" }}>
                <CustomText weight={500} lg={16} md={14}>{error}</CustomText>
            </Box>
            <Form
                layout="vertical"
                form={form}
                onFinish={signup}
            >
                <Form.Item
                    name="fullname"
                    label={<label className="form-label">Full name</label>}
                >
                    <Input bordered={false} className="form-input-v2" type="text" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={<label className="form-label">E-mail</label>}
                >
                    <Input bordered={false} className="form-input-v2" type="text" />
                </Form.Item>
                <Space direction="horizontal">
                    <Form.Item
                        name="password"
                        label={
                            <Space direction="horizontal">
                                <label className="form-label">Password</label>
                                <Tooltip placement="topLeft" title={utils.PasswordHelper()}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Space>
                        }
                    >
                        <Input.Password bordered={false} className="form-input-v2" />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        label={<label className="form-label">Confirm Password</label>}
                    >
                        <Input.Password bordered={false} className="form-input-v2" />
                    </Form.Item>
                </Space>
                <Form.Item style={{ marginTop: "10px" }}>
                    <CustomButton br="7px" padding="5px 0" className="form-button" htmlType="submit">
                        
                        Sign up
                    </CustomButton>
                    
                </Form.Item>
                <Spin className="loading" spinning={spin}
                        indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
                    />
                <Form.Item style={{ textAlign: "left" }}>
                    <CustomText lg={14} md={12} weight={600}>
                        Already have an account? <span style={{ color: "red", cursor: "pointer" }} onClick={onLogin}>Login</span>
                    </CustomText>
                </Form.Item>
            </Form>
        </Box>
    );
}
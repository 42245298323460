import * as moment from "moment";
import Carousel from "react-multi-carousel";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import "../../Common/less/todo.less";
import { MESSAGE } from "../../../lib/constants/message";
import { Button, Col, Dropdown, Image, Menu, Row, Space } from "antd";
import { CustomText } from "../../Common/CustomText";
import { ConfirmModal } from "../../Common/ConfirmModal";

import { CheckCircleFilled, RightOutlined, LeftOutlined, EllipsisOutlined } from '@ant-design/icons';

var carousel = React.createRef();

const Wrapper = styled.div`
    margin-top: 20px;
`;

const getDateList = (startDate) => {
    let listOfDates = [];
    for (var i = 1; i <=15; i++) {
        listOfDates.push(startDate)
        startDate = moment(startDate).add(1, "d")
    }
    return listOfDates
}

export const TodoDetail = (props) => {
    const { 
        currentDate, tasksByDate = {},
        goalNameIdMap, stepNameIdMap,
        addNewTaskByDate, goBackPreviousCalendarView,
        editTask, deleteTask, completeUndoTask
    } = props;

    const [ deletedTask, setDeletedTask ] = useState({});

    const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);

    const [ dateList, setDateList ] = useState(getDateList(currentDate))

    const onCloseConfirmModal  = () => {
        setDeletedTask({});
        setOpenConfirmDialog(false);
    }

    const processTaskDeletion = () => {
        setOpenConfirmDialog(false);
        deleteTask(deletedTask);
    }
    
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5.5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3.5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2.5
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1.5
        }
    }

    const onPrev = () => {
        carousel.previous();
    }

    const onNext = () => {
        carousel.next();
    }

    const resetToday = (e) => {
        e.preventDefault()
        const today = moment()
        setDateList(getDateList(today))
    }

    const options = (currentTask) => {
        function handleDropdown(e) {
            if (e.key === "edit") {
                editTask(currentTask)
            } else if (e.key === "remove") {
                setDeletedTask(currentTask)
                setOpenConfirmDialog(true)
            } else if (e.key === "complete" || e.key === "incomplete") {
                currentTask.isComplete = !currentTask.isComplete
                completeUndoTask(currentTask)
            }
        }

        return (
            <Menu onClick={handleDropdown} className="task-option">
                <Menu.Item key="edit">
                    Edit
                </Menu.Item>
                <Menu.Item key={currentTask.isComplete ? "complete" : "incomplete"}>
                    {currentTask.isComplete ? "Undo" : "Complete" }
                </Menu.Item>
                <Menu.Item key="remove" style={{color: "#EF4046"}}>
                    Remove
                </Menu.Item>
            </Menu>
        )
    }

    const renderTasks = (date) => {
        const dateStr = moment(date).format("MMM-DD-YYYY")
        const tasks = tasksByDate.hasOwnProperty(dateStr) ? tasksByDate[dateStr] : [];

        return (
            tasks.map((task, iTask) => {
                return (
                    <Row key={iTask} className="todo-detail-item">
                        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                            <CheckCircleFilled style={{ fontSize: "30px", color: `${task.color}`}} />
                        </Col>
                        <Col xs={21} sm={21} md={21} lg={21} xl={21} xxl={21} style={{ paddingRight: "3%" }}>
                            <Row style={{ justifyContent: "space-between" }}>
                                <Col className={`${task.isComplete ? "task-complete" : "task-incomplete"}`}>
                                    <CustomText lg={16} md={14} weight={600} ls="-0.025em" ratio={1.2}>
                                        {task.name}
                                    </CustomText>
                                </Col>
                                <Col>
                                    <Dropdown overlay={options(task)} trigger={['click']} placement="bottomLeft">
                                        <EllipsisOutlined style={{ fontSize: "20px", color: "#B3B3B3"}} />
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "2%", paddingBottom: "2%"}}>
                                <CustomText lg={12} md={10} weight={500} ls="-0.025em" ratio={1.2} 
                                    className="task-description-display">
                                    {task.description}
                                </CustomText>
                            </Row>
                            <Row justify="end" style={{ paddingTop: "5%", alignItems: "center"}}>
                                { goalNameIdMap.hasOwnProperty(task.goalId) &&
                                    <CustomText className="task-goal-tag"
                                        ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                        {goalNameIdMap[task.goalId]}
                                    </CustomText>
                                }
                                &nbsp;
                                { goalNameIdMap.hasOwnProperty(task.goalId)  && stepNameIdMap.hasOwnProperty(task.stepId) &&
                                    <RightOutlined style={{ color: "#a5a5a5", fontSize: '9px'}}></RightOutlined>
                                }
                                &nbsp;
                                { stepNameIdMap.hasOwnProperty(task.stepId) &&
                                    <CustomText className="task-step-tag"
                                        ls="-0.025em" weight={500} lg={12} md={10} ratio={1.2}>
                                        {stepNameIdMap[task.stepId] }
                                    </CustomText>
                                }
                            </Row>
                        </Col>
                    </Row>
                )
            })
        )
    }

    return (
        <Wrapper>
            { openConfirmDialog && 
                    <ConfirmModal isOpen={openConfirmDialog} title="Task" message={MESSAGE.DELETION_CONFIRM("task")} onClose={onCloseConfirmModal}
                    action="Delete" onConfirmAction={processTaskDeletion}/>
                    
            }
            <Row justify="space-between">
                <Image className="resize-icon hand-cursor" src="/activities/back-button.png" 
                    preview={false} onClick={() => goBackPreviousCalendarView()}/>
                <Space direction="horizontal" size="small">
                    <Button onClick={e => resetToday(e)} className="today-button">
                        Today
                    </Button>
                    <LeftOutlined style={{ fontSize: "20px", marginLeft: "15px"}} onClick={() => onPrev()} />
                    <RightOutlined style={{ fontSize: "20px"}} onClick={onNext}/>
                </Space>
            </Row>
            <Carousel responsive={responsive}
                key={dateList}
                arrows={false}
                swipeable={false}
                draggable={false}
                ref={(el) => (carousel = el)}>
                {
                    dateList.map((date,idx) => (
                        <div key={idx} className="todo-detail">
                            <Row style={{ paddingTop: "20px", justifyContent: "space-between"}}>
                                <Col>
                                    <Row>
                                        <CustomText lg={20} md={18} weight={600} ls="-0.025em" ratio={1.2}>{moment(date).format("MMMM DD")}</CustomText>
                                    </Row>
                                    <Row>
                                        <CustomText lg={14} md={12} weight={600} ls="-0.025em" ratio={1.5}>{moment(date).format("dddd")}</CustomText>
                                    </Row>
                                </Col>
                                <Col>
                                    <Button className="new-task-btn" onClick={(e) => addNewTaskByDate(e, date)}>New Task</Button>
                                </Col>
                            </Row>
                            <div style={{ marginTop : "5%"}}>
                                {renderTasks(date)}
                            </div>
                        </div>
                    ))     
                }
            </Carousel>
        </Wrapper>    
    )
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';

import {Provider, Client, dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { getToken } from './lib/helper/token';

const cache = cacheExchange({})

const client = new Client({
  url: process.env.REACT_APP_URL,
  fetchOptions: () => {
    const token = getToken();
    return {
      headers: { 
        Authorization: token ? `Bearer ${token}` : '',
        'Access-Control-Allow-Origin': '*'
      }
    }
  },
  exchanges: [dedupExchange, cache, fetchExchange]
})

WebFont.load({
  google: {
    families: ['Inter:300,400,700', 'sans-serif']
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState  } from "react";
import { Modal, Button, Input, Form, DatePicker, Upload, Image, Space, Spin } from 'antd';
import '../Common/less/modal-create.less';
import { CustomText } from "./CustomText";
import { Box } from "./CustomLayout";
import { useMutation } from "urql";
import { fileService } from "../../queries/fileupload.requests";
import { utils } from "../../lib/helper/utils";

import * as  moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { LoadingOutlined } from "@ant-design/icons";
import { goalRequests } from "../../queries/goal.requests";
import { stepRequests } from "../../queries/step.requests";
import { InfoModal } from "./InfoModal";

const getRequest = (type, data) => {
    if (type === "goal") {
        return Object.keys(data).length === 0 ? goalRequests.GOAL_CREATION : goalRequests.GOAL_UPDATE;
    } else {
        return Object.keys(data).length === 0 ? stepRequests.STEP_CREATION : stepRequests.STEP_UPDATE;
    }
}

export const ModalCreate = (props) => {
    const { isOpen, type, userId, updateStateFromChild } = props;
    const [ form ] = useForm();
    const [ data, setData ] = useState(props.hasOwnProperty("data") ? props.data : {});
    const [ imageUrl, setImageUrl] = useState(data.url);
    const [ loading, setLoading ] = useState(false);
    const [ file, setFile] = useState(null);
    const [ error, setError] = useState('');
    const request = getRequest(type, data);

    const [executionResult, execution] = useMutation(request);

    const saveData = async (values) => {
        setLoading(true);

        if (file !== null) {
            const searchId =  data.hasOwnProperty("id") ? `${type}_${data.id}` : null;
            fileService.uploadFile(file, searchId, userId).then(response => {
                upsertRecord(values, response.data);
            })
        } else {
            upsertRecord(values);
        }   
    }

    const upsertRecord = (values, photo = null) => {
        const variables = { 
            name: form.isFieldTouched("name") && values.name.length > 0 ? values.name : undefined, 
            dueDate: form.isFieldTouched("dueDate") ? values.dueDate.format("YYYY-MM-DD"): undefined, 
            description: form.isFieldTouched("description") && values.description.length > 0 ? values.description : undefined,
            photoId: photo ? photo.photoId : null,
            goalId: props.goalId,
            id: data.id
        };

        if (!form.isFieldsTouched() && variables.hasOwnProperty("id")) {
            console.log("No action is required");
            updateStateFromChild({id: data.id, ...photo});
        } else {
            console.log("Add/Update item ");
            execution(variables).then(result => {
                setLoading(false);
                if (result.error) {
                    console.log(`Error: ${result.error.graphQLErrors[0].message}`);
                } else {
                    console.log(`result ${JSON.stringify(result.data)}`);
                    updateStateFromChild(result.data);
                }
            });
        }
    }

    const handleChange = info => { 
        setFile(info.file);
    }

    const handleCancel = () => {
        updateStateFromChild(null);
    }

    const preUpload = (file) => {

        const reader = new FileReader();

        reader.onloadend = e => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
    }

    const initData = {
        name: data.name,
        description: data.description,
        dueDate: data.hasOwnProperty("dueDate") ? moment(data.dueDate, "YYYY-MM-DD") : null
    };

    return (
        <Modal 
            animation={false} 
            title={ <CustomText weight={600} lg={22} ratio={1.3}>Add new {utils.capitalize(type)}</CustomText>}
            centered
            visible={isOpen} 
            onCancel={handleCancel}
            maskClosable = {false}
            style={{top: 0, borderRadius: "16px"}}
            footer={null}
            wrapClassName="create-modal">
            <Spin className="loading" spinning={loading} 
                indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
            />
            <Form
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                onFinish={saveData}
                initialValues={initData}
                form={form}
                >
                <Form.Item name="name"
                        rules={[{ required: true, message: `Please enter your ${type} name`}]}>
                    <Input size="large" bordered={false} placeholder={`${utils.capitalize(type)} Name`} className="form-input-v2"/>
                </Form.Item>
                <Form.Item name="description">
                    <Input.TextArea size="large" bordered={false}
                        autoSize={{ minRows: 3, maxRows: 9 }} 
                        placeholder={`${utils.capitalize(type)} Description`} className="form-input-v2"
                    />
                </Form.Item>
                <Form.Item name="dueDate"
                    rules={[{ required: true, message: `Please select the due date`}]}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        className="form-date-picker"
                        placeholder="Due Date"
                        allowClear={false}
                        suffixIcon={
                            <Image height={15} width={15} src="/activities/calendar.png"/>
                        }/>
                </Form.Item>  
                <Form.Item className="file-upload-item">
                    <Upload.Dragger 
                        name="avatar" accept="image/*"
                        listType="picture-card"
                        className="avatar-uploader"
                        multiple={false} maxCount={1} 
                        showUploadList={false}
                        beforeUpload={preUpload}
                        onChange={handleChange}>
                        <Box className="file-display">
                            {
                                imageUrl ? 
                                <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%" }} /> :
                                <Space direction="vertical">
                                    <span className="ant-upload-drag-icon">
                                        <Image height={40} width={50} src="/activities/upload.png" preview={false} />
                                    </span>
                                    <span className="ant-upload-text" style={{ color: "#A3A3A3" }}>Click to upload image</span>
                                </Space>
                            }
                        </Box>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item>
                    <Space size="small">
                        <Button key="submit" type="primary" htmlType="submit"
                            style={{ backgroundColor: "#EF4046", borderRadius: "3px" }}>
                            {Object.keys(data).length === 0 ? "Create" : "Update"}
                        </Button>
                        <Button key="back" onClick={handleCancel} 
                            style={{ border: "1px solid #EF4046", color: "#EF4046", borderRadius: "3px"}}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <InfoModal isOpen={error.length > 0} title="Error" message={error} onClose={handleCancel}/>
        </Modal>
    );
}
import React, { Component } from "react";
import { Home } from "../../components/Home/home";


export class HomeLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.location.user
        };
    }

    componentDidMount() {
        const idx = Math.floor(Math.random() * 2) + 1;
        this.setState({
            homepageBg: `../../home/home-page-${idx}.png`
        });
    }


    render() {
        const { homepageBg, user } = this.state;
        return (
            <Home homepage={homepageBg} user={user}/>
        )
    }
}